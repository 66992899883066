<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
    >
      <div class="formgrid p-fluid grid">
        <!-- Job Name -->
        <div class="field col-6">
          <label for="jobName">Job Name</label>
          <div class="p-inputgroup">
            <InputText
              id="jobName"
              v-model="recurring.jobName"
              placeholder="Job Name"
            />
          </div>
          <div
            v-if="submitted && !recurring.jobName"
            class="error-validation-text pt-1"
          >
            Job name is required
          </div>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup pt-4">
            <div class="font-light font-smaller">
              <span style="font-weight:600">Use dynamic naming variables<br /></span>
              include {month}, {year}, or {client} variables in the Job name for auto period naming.
            </div>
          </div>
        </div>

        <!-- Job Template -->
        <div class="field col-6">
          <label for="jobTemplate">Use Job Template</label>
          <div class="p-inputgroup">
            <Dropdown
              id="jobTemplate"
              :options="jobTemplates"
              optionLabel="title"
              optionValue="uid"
              v-model="recurring.jobTemplate"
              placeholder="Select Template"
            />
          </div>
          <div
            v-if="submitted && !recurring.jobTemplate"
            class="error-validation-text pt-1"
          >
            Job template is required
          </div>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup">
            <div class="font-light font-smaller pt-2">
              <span style="font-weight:600">Examples:</span>
              <li class="pt-2 pl-2">{client} {month+1} Retainer</li>
              <li class="pt-2 pl-2">ANC {year} Hosting</li>
              <li class="pt-2 pl-2">{month} Content Calendar</li>
            </div>
          </div>
        </div>

        <!-- Client -->
        <div class="field col-6">
          <label for="client">Client</label>
          <div class="p-inputgroup">
            <Dropdown
              id="client"
              :options="clients"
              optionLabel="name"
              optionValue="uid"
              v-model="recurring.client"
              placeholder="Select Client"
            />
          </div>
          <div
            v-if="submitted && !recurring.client"
            class="error-validation-text pt-1"
          >
            Client is required
          </div>
        </div>
        <div class="field col-6"></div>

        <!-- Repeat Every -->
        <div class="field col-6">
          <label for="repeatEvery">Repeat Every</label>
          <div class="p-inputgroup">
            <InputNumber
              id="repeatEvery"
              v-model="recurring.repeatValue"
              min="1"
              max="12"
              class="w-1"
            />
            <Dropdown
              :options="repeatIntervals"
              v-model="recurring.repeatInterval"
              optionLabel="label"
              optionValue="value"
              class="w-8"
              placeholder="Interval"
            />
          </div>
          <div
            v-if="submitted && !recurring.repeatInterval"
            class="error-validation-text pt-1"
          >
            Interval is required
          </div>
        </div>

        <!-- On Day Dropdown for Monthly Recurrence -->
        <div class="field col-6">
          <template v-if="recurring.repeatInterval === 'months'">
            <label for="recurDay">On Day</label>
            <div class="p-inputgroup">
              <Dropdown
                id="recurDay"
                v-model="recurring.recurDay"
                :options="Array.from({length: 28}, (_, i) => ({label: String(i+1), value: i+1}))"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Day"
                :disabled="recurring.lastDayOfMonth"
              />
            </div>

            <div
              v-if="submitted && recurring.repeatInterval === 'months' && !recurring.recurDay && !recurring.lastDayOfMonth"
              class="error-validation-text pt-1"
            >
              Day of month is required
            </div>
          </template>

          <template v-if="recurring.repeatInterval === 'weeks'">
            <label for="recurDay">On Day</label>
            <div class="p-inputgroup">
              <Dropdown
                id="recurDay"
                v-model="recurring.recurDay"
                :options="[
                {label: 'Monday', value: 1},
                {label: 'Tuesday', value: 2},
                {label: 'Wednesday', value: 3},
                {label: 'Thursday', value: 4},
                {label: 'Friday', value: 5},
                {label: 'Saturday', value: 6},
                {label: 'Sunday', value: 7}
              ]"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Day"
              />
            </div>
            <div
              v-if="submitted && recurring.repeatInterval === 'weeks' && !recurring.recurDay"
              class="error-validation-text pt-1"
            >
              Day of week is required
            </div>
          </template>

        </div>

        <!-- Notify Account Executive -->
        <div class="field col-6">
          <div class="field-checkbox">
            <Checkbox
              id="notifyAE"
              v-model="recurring.notifyAccountExecutive"
              :binary="true"
            />
            <label
              for="notifyAE"
              class="ml-2"
            >Notify Account Executive</label>
          </div>
        </div>

        <!-- Next Occurrence (Read-only) -->
        <div class="field col-6">
          <!-- <template v-if="jobProp">
            <label for="next">Next Occurrence</label>
            <div class="p-inputgroup">
              <InputText
                id="next"
                :value="recurring.recurNext"
                :disabled="true"
              />
            </div>
          </template> -->

          <div
            class="field-checkbox"
            v-if="recurring.repeatInterval === 'months'"
          >
            <Checkbox
              id="lastDayOfMonth"
              v-model="recurring.lastDayOfMonth"
              :binary="true"
            />
            <label
              for="lastDayOfMonth"
              class="ml-2"
            >Last day of month</label>
          </div>
        </div>

        <!-- Ends Section -->
        <div class="field col-6 align-content-center">
          <label>Ends</label>
          <div class="field-checkbox pt-2">
            <Checkbox
              inputId="never"
              value="never"
              v-model="recurring.endOption"
            />
            <label for="never">Never</label>
          </div>
        </div>
        <div class="col-6"></div>
        <div class="field col-6 align-content-center">
          <div
            class="field-checkbox"
            style="height:40px"
          >
            <Checkbox
              inputId="on"
              value="on"
              v-model="recurring.endOption"
            />
            <label for="on">On</label>
            <Calendar
              v-model="recurring.endDate"
              :disabled="!recurring.endOption.includes('on')"
              :showIcon="true"
              placeholder="Select Date"
              class="ml-3"
              dateFormat="yy-mm-dd"
            />
          </div>
        </div>
        <div class="col-6"></div>
        <div class="field col-6 align-content-center">
          <div
            class="field-checkbox"
            style="height:40px"
          >
            <Checkbox
              inputId="after"
              value="after"
              v-model="recurring.endOption"
            />
            <label for="after">After</label>
            <InputNumber
              v-model="recurring.endOccurrences"
              class="ml-3 input-w-50"
              min="1"
              :disabled="!recurring.endOption.includes('after')"
            />
            <label
              class="ml-2"
              :style="!recurring.endOption.includes('after') ? 'color:#bdbdbd' : 'color: #687a8d !important'"
            >Occurrences</label>
          </div>
        </div>
      </div>

      <div class="field col-12"></div>

      <!-- Buttons -->
      <div class="flex justify-content-between mt-4">
        <Button
          type="submit"
          :label="jobProp ? 'Update' : 'Save'"
        />
        <Button
          type="button"
          @click="clearSliderContent"
          label="Cancel"
          class="p-button-text p-button-secondary"
        />
      </div>
    </form>
  </div>
</template>

<script>
import {
  fetchClientsSummary,
  fetchTemplates,
  state,
} from "../../../services/data_service";

export default {
  name: "RecurringForm",
  async mounted() {
    this.$store.dispatch("setSliderLoader", true);
    await Promise.all([fetchClientsSummary(), fetchTemplates()]);

    if (this.jobProp) {
      // Editing an existing job
      this.recurring = {
        jobName: this.jobProp.job_name,
        jobTemplate: this.jobProp.job_uid,
        client: this.jobProp.client,
        repeatValue: this.jobProp.recur_interval,
        repeatInterval: this.inferRepeatInterval(this.jobProp.recur_interval),
        endOption: this.determineEndOption(this.jobProp.recur_until),
        endDate:
          this.jobProp.recur_until === "2099-12-31"
            ? null
            : this.parseDateString(this.jobProp.recur_until), // Parse date string
        endOccurrences: this.jobProp.recur_occurences,
        notifyAccountExecutive: false,
        active: !!this.jobProp.active,
        recurNext: this.jobProp.recur_next,
      };
    } else {
      // Creating a new job
      this.recurring = {
        jobName: "",
        jobTemplate: null,
        client: null,
        repeatValue: 1,
        repeatInterval: null,
        endOption: ["never"],
        endDate: null,
        endOccurrences: 1,
        notifyAccountExecutive: false,
        active: true,
        recurNext: null,
      };
    }

    this.$store.dispatch("setSliderLoader", false);
  },
  props: {
    jobProp: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      submitted: false,
      recurring: {},
      repeatIntervals: [
        { label: "Day(s)", value: "days" },
        { label: "Week(s)", value: "weeks" },
        { label: "Month(s)", value: "months" },
        { label: "Year(s)", value: "years" },
      ],
    };
  },
  computed: {
    clients() {
      return state.clientsSummary;
    },
    jobTemplates() {
      return state.templates;
    },
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        event.stopPropagation();
      } else {
        this.submitHandler();
      }
    },
    async submitHandler() {
      this.submitted = true;
      if (
        !this.recurring.jobName ||
        !this.recurring.jobTemplate ||
        !this.recurring.repeatInterval ||
        !this.recurring.client
      ) {
        return;
      }
      this.$store.dispatch("setSliderLoader", true);

      const payload = {
        job_name: this.recurring.jobName,
        job_uid: this.recurring.jobTemplate,
        client_uid: this.recurring.client,
        recur_day: this.recurring.lastDayOfMonth
          ? "last"
          : this.recurring.recurDay,
        recur_next: this.formatDate(new Date()), // Format date
        recur_interval: this.recurring.repeatValue,
        recur_type: this.recurring.repeatInterval?.charAt(0) || "d",
        recur_until: this.getRecurUntil(),
        active: this.recurring.active ? 1 : 0,
      };

      const url = this.jobProp
        ? `/v1/jobs/recur/${this.jobProp.uid}`
        : "/v1/jobs/recur";

      try {
        const response = await this.$axios.post(url, payload, {
          headers: { "Content-type": "application/json" },
        });

        this.$toast.add({
          severity: "success",
          detail: this.jobProp
            ? "Recurring Job Updated"
            : "Recurring Job Created",
          life: 3000,
        });
      } catch (error) {
        console.error("Failed to save recurring job:", error);
        this.$toast.add({
          severity: "error",
          detail: "There was an error saving the job",
          life: 3000,
        });
      } finally {
        this.$bus.emit("refreshData");
        this.$store.dispatch("setSliderLoader", false);
      }
    },
    // Helper function to format date as YYYY-MM-DD
    formatDate(date) {
      if (!date) return null;
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // Helper function to parse date string YYYY-MM-DD to Date object
    parseDateString(dateString) {
      if (!dateString) return null;
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day); // Month is 0-indexed
    },

    getRecurUntil() {
      if (this.recurring.endOption.includes("never")) {
        return "2099-12-31";
      }
      if (this.recurring.endOption.includes("on")) {
        return this.formatDate(this.recurring.endDate); // Format the date
      }
      if (this.recurring.endOption.includes("after")) {
        const currentDate = new Date();
        const newMonth = currentDate.getMonth() + this.recurring.endOccurrences;
        const recurUntil = new Date(
          currentDate.getFullYear(),
          newMonth,
          currentDate.getDate()
        );
        return this.formatDate(recurUntil);
      }
      return null;
    },
    inferRepeatInterval(interval) {
      if (interval % 365 === 0) return "years";
      if (interval % 30 === 0) return "months";
      if (interval % 7 === 0) return "weeks";
      return "days";
    },
    determineEndOption(recurUntil) {
      if (recurUntil === "2099-12-31") {
        return ["never"];
      } else if (recurUntil) {
        return ["on"];
      }
      return [];
    },
    clearSliderContent() {
      this.submitted = false;
      this.recurring = {
        jobName: "",
        jobTemplate: null,
        client: null,
        repeatValue: 1,
        repeatInterval: null,
        endOption: ["never"],
        endDate: null,
        endOccurrences: 1,
        notifyAccountExecutive: false,
        active: true,
        recurNext: null,
      };
    },
  },
  watch: {
    "recurring.endOption": function (val) {
      if (val?.length > 1) {
        this.recurring.endOption = [val[val.length - 1]];
      }
    },
  },
};
</script>

<style scoped>
</style>